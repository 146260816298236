// App.js
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Paper,
  Alert,
  Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  QrCodeScanner as QrCodeScannerIcon,
  FlipCameraAndroid as FlipCameraIcon,
} from '@mui/icons-material';
import QrScanner from './QRScanner';

function App() {
  const [data, setData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [error, setError] = useState(null);
  const [facingMode, setFacingMode] = useState('environment'); // Use rear camera

  const handleScanSuccess = (decodedText) => {
    setData(decodedText);
    setScanning(false);
  };

  const handleStartScan = () => {
    setData('');
    setError(null);
    setScanning(true);
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment'
    );
  };

  // Custom theme for the app
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2', // Blue color
      },
      secondary: {
        main: '#f50057', // Pink color
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {/* App Bar */}
      <AppBar position="static">
        <Toolbar>
          <QrCodeScannerIcon
            fontSize="large"
            style={{ marginRight: '0.5rem' }}
          />
          <Typography variant="h6" component="div">
            QR Code Scanner
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Main Container */}
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Paper elevation={3} style={{ padding: '2rem' }}>
          <Box textAlign="center">
            <Typography variant="h4" gutterBottom>
              Welcome to the QR Puzzle Scanner
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Scan QR codes to reveal puzzles and challenges!
            </Typography>

            {/* Start Scan Button */}
            {!scanning && !data && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<QrCodeScannerIcon />}
                onClick={handleStartScan}
                style={{ marginTop: '1rem' }}
              >
                Start Scanning
              </Button>
            )}

            {/* QR Scanner */}
            {scanning && (
              <Box style={{ position: 'relative', marginTop: '1rem' }}>
                <QrScanner
                  onScanSuccess={handleScanSuccess}
                  facingMode={facingMode}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: '0.5rem' }}
                >
                  Point your camera at a QR code
                </Typography>
                {/* Camera Toggle Button */}
                <Button
                  variant="text"
                  onClick={toggleFacingMode}
                  startIcon={<FlipCameraIcon />}
                  style={{ marginTop: '1rem' }}
                >
                  Switch Camera
                </Button>
              </Box>
            )}

            {/* Display Scanned Data */}
            <Box mt={3}>
              {error && (
                <Alert severity="error" onClose={() => setError(null)}>
                  {error}
                </Alert>
              )}
              {data && (
                <Paper
                  elevation={2}
                  style={{ padding: '1.5rem', backgroundColor: '#e3f2fd' }}
                >
                  <Typography variant="h5" gutterBottom>
                    Puzzle
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontStyle: 'italic' }}
                  >
                    {data}
                  </Typography>
                  {/* Add a button to scan another QR code */}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleStartScan}
                    style={{ marginTop: '1rem' }}
                  >
                    Scan Another
                  </Button>
                </Paper>
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default App;
